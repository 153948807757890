import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"
import ATG_logo from "../../images/atg.png"
import Seed_Planet from "../../images/atg/seed_planet.png"
import ATG_Ceo from "../../images/atg/atg_owner.jpg"
import ATG_story from "../../images/atg/story-1.gif"
import ATG_Service from "../../images/atg-conv/service-rgt.png"
import ATG_Challenge from "../../images/atg/challenge.png"
import ATG_Foot from "../../images/atg/micro-kit-1.png"

import Owner from "../../images/drB/drBOwner.png"
import DrB_logo from "../../images/drB/drbLogo.png"
import brushpack from "../../images/drB/brushpack.png"
import toothpaste from "../../images/drB/toothpaste.png"

import ATG_Conv from "../../images/atg-conv/atg-conversiontop.png"

import Scroll_top_img from "../../images/scroll-arrow.png"
import {Helmet} from "react-helmet"

import { Link } from "gatsby";
import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Dental Ecommerce Case Study: 215% Revenue in Revenue </title>
                    <meta name="description"
                        content="Google Shopping ads drove a 215% revenue increase for Dr. B Dental Solutions. Read about the strategic approach and the results achieved." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Dental Ecommerce Case Study: 215% Revenue in Revenue" />
                    <meta property="og:description"
                        content="Google Shopping ads drove a 215% revenue increase for Dr. B Dental Solutions. Read about the strategic approach and the results achieved." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="Dental Ecommerce Case Study: 215% Revenue in Revenue" />
                    <meta name="twitter:title"
                        content="Dental Ecommerce Case Study: 215% Revenue in Revenue" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page high-roas-atg seed-ecommerce-atg drB-caseStudy">

                     <div className="case-wrap">
                           <div className="ww-sec-one ">
                              <div className="container">

                                 <div className="cycle-flex">
                                    <div className="cycle-left">
                                        <h3>Case Study</h3>
                                        <h1><img src={DrB_logo} alt="drb logo" /></h1>
                                        <h2>How CueForGood Helped Dr. B Dental Solutions Achieve a Whopping 215% Revenue Growth with Google Shopping Ads</h2>
                                    </div>
                                    <div className="cycle-top"><img src={Owner} alt="AllThatGrows Achieves 2.97x ROAS & 500+ Conversions" /></div>
                                 </div>
                              </div>
                           </div>
                     </div>

                     <div className="overall-roas ">
                        <div className="container">
                           <div className="out-roas-full">
                              <h3>Introduction</h3>
                              <p>
                              Dr. B Dental Solutions, founded by Dr. Lorin Berland, is a company focusing on oral appliance
                                issues like oral infections and dry mouth. Dr. Berland, fondly called Dr. B, is a published
                                author with over 100 articles, the Lorin Library Smile Style Guide, and instructional videos. He
                                has developed a full line of dental solutions for patients.
                              </p>
                              <p>
                              Dr. B and his team wanted to enhance their online sales and reach more customers. To achieve this, they turned to Google Shopping - a powerful advertising platform. With CueForGood, they were able to showcase their dental products to a wider audience and drive more sales.
                              </p>
                           </div>
                        </div>
                     </div>

                     <div className="overall-statics ">
                        <div className="container">
                           <h3>Statistics</h3>
                           <div className="out-statics">
                              <div className="inn-statics">
                                 <span>
                                 <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.03286 0C1.29108 0 1.5493 0.103287 1.75587 0.309859L6.92019 5.52582L10.7934 2.11737C11.1549 1.75587 11.7746 1.75587 12.1361 2.11737L17.9202 7.38498V4.23474C17.9202 3.66667 18.385 3.20188 18.953 3.20188C19.5211 3.20188 19.9859 3.66667 19.9859 4.23474V9.96714C19.9859 10.5352 19.5211 11 18.953 11H13.169C12.6009 11 12.1361 10.5352 12.1361 9.96714C12.1361 9.39906 12.6009 8.93427 13.169 8.93427H16.6291L11.4131 4.23474L7.48826 7.69484C7.07512 8.05634 6.4554 8.00469 6.0939 7.64319L0.309859 1.75587C-0.103286 1.34272 -0.103286 0.723005 0.309859 0.309859C0.516432 0.103287 0.774648 0 1.03286 0Z" fill="black"/></svg>
                                <span className="statics-text">Decreased by</span>
                                </span>
                                 <div className="main-tile">64.05%</div>
                                 <p>Cost Per Conversion</p>
                              </div>
                              <div className="inn-statics">
                                 <span>
                                 <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.03286 11C1.29108 11 1.5493 10.8967 1.75587 10.6901L6.92019 5.47418L10.7934 8.88263C11.1549 9.24413 11.7746 9.24413 12.1361 8.88263L17.9202 3.61502V6.76526C17.9202 7.33333 18.385 7.79812 18.953 7.79812C19.5211 7.79812 19.9859 7.33333 19.9859 6.76526V1.03286C19.9859 0.464789 19.5211 0 18.953 0H13.169C12.6009 0 12.1361 0.464789 12.1361 1.03286C12.1361 1.60094 12.6009 2.06573 13.169 2.06573H16.6291L11.4131 6.76526L7.48826 3.30516C7.07512 2.94366 6.4554 2.99531 6.0939 3.35681L0.309859 9.24413C-0.103286 9.65728 -0.103286 10.277 0.309859 10.6901C0.516432 10.8967 0.774648 11 1.03286 11Z" fill="black"/></svg>
                                 <span className="statics-text">Increased by</span>
                                 </span>
                                 <div className="main-tile">226.38%</div>
                                 <p>Conversion Rate</p>
                              </div>
                              <div className="inn-statics">
                                 <span>
                                 <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.03286 11C1.29108 11 1.5493 10.8967 1.75587 10.6901L6.92019 5.47418L10.7934 8.88263C11.1549 9.24413 11.7746 9.24413 12.1361 8.88263L17.9202 3.61502V6.76526C17.9202 7.33333 18.385 7.79812 18.953 7.79812C19.5211 7.79812 19.9859 7.33333 19.9859 6.76526V1.03286C19.9859 0.464789 19.5211 0 18.953 0H13.169C12.6009 0 12.1361 0.464789 12.1361 1.03286C12.1361 1.60094 12.6009 2.06573 13.169 2.06573H16.6291L11.4131 6.76526L7.48826 3.30516C7.07512 2.94366 6.4554 2.99531 6.0939 3.35681L0.309859 9.24413C-0.103286 9.65728 -0.103286 10.277 0.309859 10.6901C0.516432 10.8967 0.774648 11 1.03286 11Z" fill="black"/></svg>
                                 <span className="statics-text">Grew by</span>
                                 </span>
                                 <div className="main-tile">215.25%</div>
                                 <p>Revenue Growth</p>
                              </div>
                              <div className="inn-statics">
                                 <span>
                                 <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H12.0874C12.2619 0 12.4033 0.141385 12.4033 0.315789C12.4033 0.370743 12.3889 0.424749 12.3616 0.472465L10.1053 4.42105L12.3616 8.36962C12.4481 8.52107 12.3955 8.71396 12.2441 8.80048C12.1964 8.82777 12.1424 8.8421 12.0874 8.8421H1.26316V12H0V0Z" fill="black"/></svg>
                                 <span className="statics-text">Reached</span>
                                 </span>
                                 <div className="main-tile">116.44%</div>
                                 <p>Return on Ad Spend (ROAS)</p>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="about-client service-offer seeds-service">
                        <div className="container">
                           <div className="about-client-in">
                              <div className="about-client-left">
                                 <h2 className="heading_main">Analysis</h2>
                                 <p>To start, we analyzed Dr. B Dental Solutions' products and product information on their website. This helped us understand their offerings and make recommendations wherever deemed necessary.</p>
                                 <ol>
                                    <li>Their website did not state that they were offering free shipping, such as "Free shipping on orders over $70."</li>
                                    <li>We also noticed discrepancies in shipping costs between the cart page and the shipping policy page on the website.</li>
                                    <li>A recommendation was provided to showcase the offer prominently in the top header of the site.</li>
                                    <li>No coupon was mentioned on the website. We created a promo feed and emphasized the 15% off coupon to customers through the shopping listing ads.</li>
                                 </ol>
                              </div>
                              <div className="about-client-right">
                                 <img src={brushpack} alt="Brush pack" />
                              </div>
                           </div>
                           <div className="about-client-in rev">
                              <div className="about-client-left">
                                 <p>We collaborated with Dr. B Dental Solutions to fine-tune the targeting strategy, ensuring that we covered all the important aspects and potential testing opportunities.</p>
                                 <ol>
                                    <li>Product-specific keywords helped us improve the feed optimization procedure.</li>
                                    <li>For our shopping endeavors, we launched conventional shopping campaigns.</li>
                                    <li>In order to achieve better results, we modified the targeting strategy and implemented a PMax campaign strategy.</li>
                                    <li>We divided the campaign into two separate, customized campaigns to further improve the targeting.</li>
                                    <li>We created distinct campaigns for each after classifying products into high and low-performing segments.</li>
                                    <li>We distributed budgets to campaigns based on their individual performances in order to maximize efficiency.</li>
                                 </ol>
                              </div>
                              <div className="about-client-right">
                                 <img src={toothpaste} alt="mouth cleaner" />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="Challenges ">
                        <div className="container">
                        <div className="blue-bg">
                              <p>
                              Drive targeted traffic and maximize your revenue potential. Get started today and watch your business thrive! Contact us at - <a href="mailto:letstalk@cueforgood.com">letstalk@cueforgood.com</a>
                              </p>
                           </div>
                           <div className="process-inn">
                              <h2 className="heading_main">Our Process</h2>
                              <p>CueForGood started the shopping campaign for Dr. B on November 21, 2022. We created a feed and set up Dr. B's Google Merchant Center account. Once the initial setup was completed, the campaign started performing in December 2022.</p>
                              <div className="out-Challenges">
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">1</h2>
                                 <p>CueForGood recognizes the potential of Google Shopping, and so we advised Dr. B Dental Solutions to invest part of their marketing budget into running Google Shopping Campaigns. This strategic move allowed them to take advantage of the platform's ability to connect with customers directly within search results.</p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">2</h2>
                                <p>Once the targeting strategy was defined, we created a feed based on Google's specifications. The feed included all the necessary information about their products and served as a foundation for the campaign. We worked closely with Dr. B Dental Solutions to ensure the feed was accurate and functional within their Google Ads Account.</p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">3</h2>
                                 <p>With the feed in place, we began setting up the Google Shopping Campaign Targeting. This involved implementing different targeting methods to find relevant traffic at the lowest cost.</p>
                              </div>
                              <div className="inn-Challenges">
                                 <h2 className="heading_count">4</h2>
                                <p>We aimed to continuously optimize the campaign by adapting to changes in product range, trends, and seasons. This included refining targeting parameters, utilizing promotions, managing bids and budgets, and striving for ongoing improvements.</p>
                              </div>
                            </div>
                           </div>
                           <div className="process-inn result-process">
                              <h2 className="heading_main">Results</h2>
                              <p className="result-p">The campaigns successfully increased clicks, impressions, orders, and revenue while maintaining a stable CPC (Cost Per Click). Dr. B Dental Solutions achieved a favorable ROAS, indicating the effectiveness of their advertising investment.</p>
                              <div className="bottom-info">
                                 <p className="bottom-p">More Clicks and Engagement:</p>
                                 <ul>
                                    <li>July 2023 had the most clicks.</li>
                                    <li>From December 2022 to July 2023, clicks grew by 31%.</li>
                                 </ul>
                                 <p className="bottom-p">Getting Noticed:</p>
                                 <ul>
                                    <li>Impressions increased by 44.4% between May 2023 and July 2023.</li>
                                 </ul>
                                 <p className="bottom-p">Consistent Interest:</p>
                                 <ul>
                                    <li>An average of 1,913 clicks per month.</li>
                                 </ul>
                                 <p className="bottom-p">Better Conversions:</p>
                                 <ul>
                                    <li>In May 2023, the conversion rate was 6.08%.</li>
                                 </ul>
                                 <p className="bottom-p">Great Orders and Revenue:</p>
                                 <ul>
                                    <li>A record 118 orders were placed in June 2023, with a monthly average of 71 that year.</li>
                                    <li>June saw the highest revenue between December 22 and June 23.</li>
                                 </ul>
                                 <p>The campaign performance has been stable and the client has seen favorable returns on their investment.</p>
                                 <p className="rs-rel"> <span className="rs-ab"><strong>NOTE: </strong></span>The above analysis is based on the performance data for Google Shopping campaigns between December 2022 and June 2023.</p>
                              </div>
                              <div className="blue-bg">
                              <p className="v1">
                              Don't want to miss out on potential sales opportunities? Take advantage of our expertise today! Reach us at <a href="mailto:letstalk@cueforgood.com">letstalk@cueforgood.com</a> to discuss your Google Shopping Ads strategy.
                              </p>
                           </div>
                           </div>
  

                        </div>
                     </div>
                    
                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}